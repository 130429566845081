import React from 'react';
import './skillwardrobe.css';

const SkillWardrobre = ({ Skills }) => {
  return (
    <div className='SkillWardrobre--wrapper'>
      <div className="details-container">
        <div className="article-container">
          {Skills.map((skill, index) => (
            <article key={index}>
              <img src="./checkmark.svg" alt="Experience icon" className="icon" />
              <div>
                <h3 className='skill--name'>{skill.name}</h3>
                <p className='skill--level'>{skill.level}</p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillWardrobre;
