import React, { useState, useEffect } from 'react';

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

 
  const updateTheme = () => {
    if (isDarkMode == true) {
   

      document.documentElement.style.setProperty('--text', '#f9f9fa');
      document.documentElement.style.setProperty('--background', '#141619');
      document.documentElement.style.setProperty('--primary', '#bec5cb');
      document.documentElement.style.setProperty('--secondary', '#2d3339');
      document.documentElement.style.setProperty('--accent', '#8e99a4');
    } else {

      document.documentElement.style.setProperty('--text', '#050506');
      document.documentElement.style.setProperty('--background', '#e6e8eb');
      document.documentElement.style.setProperty('--primary', '#343b41');
      document.documentElement.style.setProperty('--secondary', '#c6ccd2');
      document.documentElement.style.setProperty('--accent', '#5b6671');
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(mediaQuery.matches);
  }, []);
  useEffect(() => {
    updateTheme();
  }, [isDarkMode]);
  
  return (
    <img className='icon' src={isDarkMode ? '../sun.svg': '../moon.svg'} alt='Change Theme' onClick={() => {
      setIsDarkMode(!isDarkMode);
      updateTheme(); // Toggle the theme
    }}>
    </img>
  );
};

export default ThemeToggle;
