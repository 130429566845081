import './App.css';
import SkillWardrobre from './components/SkillWardrobe';
import ThemeToggle from './components/ThemeToggler';
import AboutSkill from './components/aboutSkill';
import Navbar from './components/navbar';
import React, { useEffect, useState } from 'react';
import { Analytics } from '@vercel/analytics/react';
import ReactGA from "react-ga4";

function App() {

  //Configuração do Google analytics
  ReactGA.initialize("G-GNT1V5EHNK");
  ReactGA.set({ page: window.location.pathname });

  const [skills, setSkills] = useState([
    { name: 'JavaScript', level: 'Advanced' },
    { name: 'React', level: 'Advanced' },
    { name: 'CSS', level: 'Advanced' },
    { name: 'HTML', level: 'Advanced' },
    { name: 'Next.js', level: 'Intermediate' },
    { name: 'BPMN', level: 'Intermediate' },
    { name: 'UML', level: 'Intermediate' },
    { name: 'RESTful Architecture', level: 'Intermediate' },
    { name: 'Node.js', level: 'Advanced' },
    { name: 'TypeScript', level: 'Basic' },
    { name: 'GatsbyJS', level: 'Basic' },
    { name: 'Strapi CMS', level: 'Advanced' },
    { name: 'C', level: 'Advanced' },
    { name: 'C#', level: 'Intermediate' },
    { name: 'C++', level: 'Advanced' },
    { name: 'OOP', level: 'Advanced' },
  ]);
  const handleClick = (link) => {
    window.location.href = link;
  };

  if(skills){
    return (
      
      <div className="App">
        <header className="App-header">
          <Analytics />
          <Navbar></Navbar>
          <h1 className='Section--Title'>About</h1>
          <h2 className='Section--subtitle'>Get To Know More about me!</h2>
         
          <section id="about" className='Section--About'>
          <div className='Section--About--wrapper'>
            <div className='Section--About--photo'>
            <img
              src="./PesonalPhoto.JPG"
              alt="Profile picture"
              className="about-pic"
            />
            </div>
            <div className='Section--About--Skillls--wrapper'>
              <div className='Section--About--Skills'>
                <AboutSkill
                icon = "./experience.svg"
                line1 ="Experience"
                line2={"Full-Stack Development"}
                line3={"React"}
                />
                <AboutSkill
                icon = "./education.svg"
                line1 ="Education"
                line2={"CTeSP in Information Systems Technologies and Programming "}
                line3={"Software Engineering Degree(OnGoing)"}
                />
              </div>
              <div className='Section--About--resume'>
              <p>
                  I have a true passion for discovering how technology can improve people's lives and optimize processes. I believe
                  that technology is a powerful tool for transforming businesses, enhancing efficiency, and promoting innovation.
                  From an early age, I have been fascinated by how technology shapes our world and drives progress. I closely
                  follow the latest trends and advancements in the industry, and I am constantly exploring new areas of interest.
              </p>
              </div>
     
            </div>
         
          </div>
        </section>

        <section id="experience" className='Section--Experience'>
        <h1 className='Section--Title'>Experience</h1>
        <h2 className='Section--subtitle'>Get To Know More about my Skills!</h2>
        <div className='Section--Experience--wrapper'>
          <SkillWardrobre Skills={skills}/>
        </div>
        </section>
        <section id="projects" className='Section--Projects'>
        <h1 className='Section--Title'>Projects</h1>
        <h2 className='Section--subtitle'>Get to know some of my projects!</h2>
        <div className='Section--Projects--wrapper'>
          <div className='Project--Container'>
            <img src='./CProgram.png' alt='projectIcon'></img>
            <h3>Projeto-ALG-2021</h3>
            <button onClick={() => handleClick("https://github.com/RubenDamaso/Projeto-ALG-2021")}>Github</button>
          </div>
          <div className='Project--Container'>
            <img src='./java.png'  alt='projectIcon'></img>
            <h3>Projeto_POO</h3>
            <button onClick={() => handleClick("https://github.com/RubenDamaso/Projeto_POO")}>Github</button>
          </div>
        </div>
        </section>
        
        <section id="contact" className='Section--Contact'>
        <h1 className='Section--Title'>Contacts</h1>
        <h2 className='Section--subtitle'>Lets Talk!</h2>
        <div className='Section--Contact--wrapper'>
         <div className='Section--Contact--item' onClick={() => window.location = 'mailto:rubendamaso318@gmail.com'} >
         <img src="./email.svg" alt="Experience icon" className="icon" />
         <p>rubendamaso318@gmail.com</p>
         </div>
         <div className='Section--Contact--item' onClick={() => handleClick("https://www.linkedin.com/in/r%C3%BAben-d%C3%A2maso/")}>
         <img src="./linkdin.svg" alt="Experience icon" className="icon" />
         <p>Linkdin</p>
         </div>
        </div>
        </section>
        <ThemeToggle></ThemeToggle>
        <footer>
        <p>Copyright © 2023 Rúben Dâmaso. All Rights Reserved.</p>
        </footer>
        </header>
      </div>
    );
  }
}

export default App;
