import React, { useState } from 'react';
import './aboutSkill.css'; 

const AboutSkill = ({icon,line1,line2,line3}) => {
  return (
    <>

      <div className="details-container">
        <img
          src= {icon}
          alt={line1  + " icon"}
          className="icon"
        />

        <h3>{line1}</h3>
        <p><span className="status-light"></span>{line2}</p>
        <p><span className="status-light"></span>{line3}</p>
    </div>
    </>
  );
};

export default AboutSkill;
