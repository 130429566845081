import React, { useState } from 'react';
import './navbar.css'; // Create a corresponding CSS file for styling

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      closeMenu(); // Close the mobile menu after clicking a link
    }
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <a href="/">Rúben Dâmaso</a>
        </div>
        <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
    
          <li><a href="#about" onClick={() => scrollToSection('about')} >About</a></li>
          <li><a href="#experience" onClick={() => scrollToSection('experience')} >Experience</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        <div className={`hamburger-menu ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
